import { Link } from "gatsby"
import React from "react"
import "../../styles/thank-you.scss"
import SEO from "../components/seo"
import logo from "../images/main_logo_white.svg"

const ThankYou = () => (
  <div>
    <SEO
      title="Thank You"
      description="Thank you for your submission for a wedding or special event! Look forward to discovering the YESInspired experience."
    />
    <div className="thanks-page">
      <div className="thank-you-wrapper">
        <img src={logo} alt="YESInspired logo" />
        <div className="thank-you-text-wrapper">
          <h1 className="sub-heading thanks-sub-heading">talk to you soon!</h1>
          <h2 className="heading thanks-heading">Thanks For the Submission</h2>
          <p className="thanks-body">
            Thanks for taking the time to contact us! We've received your
            message and will respond as soon as possible.
          </p>
          <button className="button ">
            <Link to="/" className="button-link">
              RETURN HOME
            </Link>
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default ThankYou
